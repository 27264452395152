<template>
    <div class="page">
        <div class="login_content">
            <div class="logo_words">
                <div class="login_left_tit">
                    <div class="top">
                        <img src="../../assets/img/3.png" alt />
                        <span class="title" style="font-size:46px;padding-left:10px">动销管理系统</span>
                    </div>
                    <div>
                        <span style="display:block;padding-top:20px" class="title">实现销售业务互联网数字化管理</span>
                        <span style="display:block;" class="title">全面提升企业效率</span>
                    </div>
                </div>
            </div>
            <a-form id="login_form" :form="form" class="login_form" @submit="handleSubmit">
                <div class="login">登录</div>
                <a-form-item>
                    <a-input class="input" v-decorator="['username',{ rules: [{ required: true, message: '请输入用户名' }] }]"
                        placeholder="请输入用户名">
                        <a-icon slot="prefix" type="user" />
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-input class="input" v-decorator="['password',{ rules: [{ required: true, message: '请输入密码' }] }]"
                        type="password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" />
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <!-- <a-checkbox v-decorator="['remember',{valuePropName: 'checked',initialValue: true}]">下次自动登录</a-checkbox>
                    <a class="login-form-forgot" href="">忘记密码</a> -->
                    <a-button type="primary" html-type="submit" class="login-form-button">登 录</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="copyright">
            <span>Copyright © 2020 江西润田实业股份有限公司 赣ICP备16012686号-1</span>
            <span>哇酷科技提供技术支持 {{this.$config.version}}</span>
        </div>





        <a-modal :confirm-loading="confirmLoading" :maskClosable='false' width='600px' height='380px'
            v-model:visible="visible" @ok="handleOk">
            <slot name="title">
                <div class='title_modal'>
                    <img src="../../assets/img/title_icon.png" class='title_icon' />
                    <span>{{ code == -6 ? '长时间未更改密码，请更改密码保障安全' : '登录密码为初始密码，请更改密码保障安全'}}</span>
                </div>
            </slot>
            <a-form ref="formRef" :form="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item ref="old_password" required label="原密码" name="old_password">
                    <!-- <a-input v-model:value="formState.old_password" placeholder='请填写原始密码' /> -->
                    <a-input placeholder='请填写原密码' v-decorator="[
					  'old_password',
					  {
					    rules: [
					      {
					        required: true,
					        message: '请填写原密码!',
					      },
					      
						  {
						    validator:  comparePassword,
						  },
					    ],
					  },
					]" type="password" />
                </a-form-item>
                <a-form-item ref="new_password" required label="修改密码" name="new_password" has-feedback>
                    <a-input @change="passwordChange($event,'new_password')" placeholder='请输入8-20个字符，包含大小写' v-decorator="[
			          'new_password',
			          {
			            rules: [
			              {
			                required: true,
			                message: '密码格式错误!',
			              },
			              {
			                validator:  compareToFirstPassword,
			              },
			            ],
			          },
			        ]" type="password" />
                </a-form-item>

                <a-form-item ref="new_password_two" required label="确认密码" name="new_password_two" has-feedback>
                    <a-input @change="passwordChange($event,'new_password_two')" placeholder='再次确认新密码' v-decorator="[
				       'new_password_two',
				       {
				         rules: [
				           {
				             required: true,
				             message: '再次确认新密码!',
				           },
				           {
				             validator: validateToNextPassword,
				           },
				         ],
				       },
				     ]" type="password" />
                </a-form-item>
            </a-form>
            <div class='bg_modal'>

            </div>
        </a-modal>
    </div>
</template>

<script>
    import {
        login,
        changePassword
    } from '@/api/user'
    import EditPop from "@/components/EditPop";
    import {
        message
    } from 'ant-design-vue'
    export default {
        components: {
            EditPop,
        },
        data() {
            return {
                confirmLoading: false,
                username: '',
                confirmDirty: false,
                labelCol: {
                    span: 4
                },
                wrapperCol: {
                    span: 20
                },

                visible: false,
                new_password: '',
                code: 0
            }
        },
        created() {
            console.log(window.location)
            window.parent.postMessage({
                data: {
                    pageType: "login"
                }
            }, '*');
            let loselogin = this.$method.get_local('loselogin');
            this.code = loselogin
            this.$method.set_local('loselogin', '');
            if (loselogin == -6) {
                this.visible = true
                this.username = this.$method.get_local('username');
            }

        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {
                name: 'login'
            });
            this.formState = this.$form.createForm(this, {
                name: 'formState'
            });
            this.$method.remove_local('token');
        },
        methods: {
            comparePassword(rule, value, callback) {
                const form = this.formState;
                this.old_password = value
                callback();
            },
            compareToFirstPassword(rule, value, callback) {
                const form = this.formState;
                // this.new_password = value
                if (this.new_password == 'Rt888888') {
                    callback('新密码不可于原始密码一致');
                }
                if (this.old_password == this.new_password) {
                    callback('原密码与修改密码不可一致');
                }

                let strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
                if (this.new_password && strongPasswordRegex.test(this.new_password)) {
                    if (this.new_password != this.new_password_two) {
                        callback('两次密码不一致');
                    }else{
                        callback();
                    }
                } else {
                    callback('密码格式错误');
                }

                if (this.new_password && this.new_password !== form.getFieldValue('new_password')) {
                    callback('密码格式错误');
                } else {
                    callback();
                }
            },
            validateToNextPassword(rule, value, callback) {
                // this.new_password_two = value
                const form = this.formState;
                if (this.new_password_two && this.new_password_two != this.new_password) {
                    callback('两次密码不一致');
                } else {
                    callback();
                }
            },
            passwordChange(e,name){
                this[name] = e.target.value
                this.$nextTick(()=>{
                    this.formState.validateFields(['new_password','new_password_two'])
                })
            },
            handleOk() {
                this.formState.validateFields(async (err, values) => {
                    this.confirmLoading = true
                    if (!err) {
                        values.username = this.username
                        changePassword(values).then(data => {
                            if (data.code == 1) {
                                message.success(data.info);
                                this.visible = false
                                this.new_password = ''
                                this.formState.resetFields();

                            }
                            if (data.code == 0) {
                                message.error(data.info);

                            }
                            setTimeout(() => {
                                this.confirmLoading = false;
                            }, 1500);
                        }).catch(error => {
                            setTimeout(() => {
                                this.confirmLoading = false;
                            }, 1500);
                        })
                    }

                });

            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        this.username = values.username
                        this.$method.set_local('username', this.username);

                        login(values).then(data => {
                            this.$method.set_local('token', data.data.token);
                            this.$method.remove_local('loselogin');
                            if (data.data.url) {
                                this.$router.replace(data.data.url)
                            } else {
                                this.$router.replace('/')
                            }
                        }).catch(err => {
                            this.code = err.code
                            if (err.code == -5 || err.code == -6) {
                                this.visible = true
                            }

                        });

                    }
                });
            }
        }
    }
</script>

<style lang="less">
    .login_left_tit {
        width: 430px;
    }

    .page {
        width: 100%;
        height: 100%;
        background: url(../../assets/img/back.png) no-repeat center;
        background-size: cover;
        overflow: hidden;
    }

    .login_content {
        display: flex;
        padding-top: 160px;
        width: 1200px;
        margin: 0 auto;
    }

    .logo_words {
        // margin: 160px 0 0 400px;
        // float: left;
        flex: 1;

        .top {
            line-height: 47px;
        }

        img {
            width: 117px;
            height: 47px;
            float: left;
        }

        .title {
            color: #fff;
            font-size: 26px;
            // float: left;
        }
    }

    .login_form {
        background: #fff;
        width: 350px;
        // margin: 160px auto 0;
        padding: 40px 32px;
        box-sizing: border-box;

        // float: right;
        // margin-right: 300px;
        .login {
            font-size: 32px;
            line-height: 32px;
            padding-bottom: 40px;
        }

        .login-form-button {
            width: 100%;
            height: 40px;
            margin-top: 20px;
        }

        .input {
            background-color: #fff;
        }
    }

    .copyright {
        position: fixed;
        bottom: 40px;
        left: 0;
        right: 0;

        span {
            display: block;
            color: #fff;
            text-align: center;
        }
    }

    /deep/.ant-modal-body {
        position: relative;

    }

    .bg_modal {
        position: absolute;
        width: 208px;
        height: 201px;
        right: 0px;
        bottom: 0px;
        background: url(../../assets/img/bg_modal.png) no-repeat;
        background-size: 100%;

    }

    .title_modal {
        margin: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
        // line-height: 22px;
        word-wrap: break-word;
        display: flex;
        align-items: center;
        margin-right: 30px;
        height: 42px;
        background: #ffffff;
        border-radius: 6px;
        box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.03);
        margin-bottom: 16px;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    }

    .title_icon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
</style>